import React from 'react'
import { graphql } from 'gatsby'
import FilterBreak from '../components/FilterBreak/FilterBreak'
import Layout from '../components/Layout/Layout'
import NewsFilter from '../components/NewsFilter/NewsFilter'
import NewsMain from '../components/NewsMain/NewsMain'
import * as styles from '../styles/News.module.scss'
import Seo from '../components/Seo/Seo'

//1. write query to grab all article information for each and pass it through to NewsMain
export const query = graphql`
  query news($skip: Int = 0, $pageSize: Int = 9) {
    articles: allSanityArticle(limit: $pageSize, skip: $skip, sort: { fields: [date], order: DESC }) {
      totalCount
      nodes {
        id
        title
        slug {
          current
        }
        categories {
          id
          title
        }
        topics {
          id
          title
        }
        date
        excerpt
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
          }
        }
        video
        typeCheck
      }
    }
    featured: sanityNewsSettings {
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      featuredArticles {
        id
        title
      }
    }
    sidebar: sanityNewsSettings {
      sidebarArticles {
        id
        title
      }
    }
    topics: allSanityTopic {
      nodes {
        title
        slug {
          current
        }
      }
    }
    categories: allSanityCategory {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }
`

const NewsTemplate = (props) => {
  const { data } = props
  // console.log(data);
  return (
    <Layout>
      <div className={styles.newsWrapper} id="news-wrapper">
        {/* <h1 id="news-header" className={styles.newsHeader}>
          <span>
            <PincTextLogo className="news-header-logo" fill="#fff" />
          </span>
          <span>Newsfeed</span>
        </h1> */}

        <FilterBreak height={120}/>

        <NewsFilter data={data} />

        <NewsMain data={data} />
      </div>
    </Layout>
  )
}

export default NewsTemplate

export const Head = (props) => {
  const { data } = props;
  return (
    <Seo title={data.featured.browserTitle} seo={data.featured.seoData} />
  )
}